@media only screen and (max-width: 900px) {
    .art {
        .case-row {
            .case-row-content {
                .case-row-content-image {
                    max-width: 100%;
            }
        }
    }
}
  }
  
  @media only screen and (min-width: 900px) {
    .art {
        .case-row {
            .case-row-content {
                .case-row-content-image {
                    max-width: 620px;
            }
        }
    }
}
  }


