
/* autoprefixer grid: on */

@import '../../variables.scss';

@media only screen and (max-width: 900px) {
    .about-text {
      max-width: 100%;
    }

    .about-photos {
        max-width: 100%;
      }
}

@media only screen and (min-width: 900px) {
    .about-text {
      max-width: 45%;
    }

    .about-photos {
        max-width: 45%;
      }
}

.about {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

.about-link-zweigrad {
    font-weight: 900;
    font-size: 2.2rem;
    text-decoration: underline;
    cursor: pointer;
}

.about-photos {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .about-photos-top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 100%;
        grid-gap: 1.5rem;
        padding-bottom: 2rem;

        .about-photos-photo-top {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    .about-photos-photo {
        width: 100%;
        margin: 1rem 0;
    }
}

.about-text {
    padding: 2rem;
    font-size: 2rem;

        .about-text-title {
            font-size: 2.8rem;
            font-weight: $bold-font;
            margin-bottom: 4rem;
        }
        
        .about-text-text {
            margin: 5rem 0 8rem 0;
        }
        
        .about-text-text-paragraph {
            margin: 4rem 0;
            font-family: $verdana;
            padding-right: 3rem;
        }

        .about-text-header {
            font-size: 2.3rem;
            font-weight: $bold-font;
            margin: 5rem 0 0 0;
        }
        
        .about-text-skills-table {
            display: grid; 
            grid-template-columns: 1fr 1fr; 
            grid-template-rows: 1fr; 
            gap: 0px 0px; 
            grid-template-areas: 
            "FirstColumn SecondColumn"; 
            font-family: $verdana;
        }
        
        .about-text-skills-table-first-column {
            grid-area: FirstColumn;
        }
        
        .about-text-skills-table-second-column{
            grid-area: SecondColumn;
        }

        .about-text-details-list {
            font-family: $verdana;

            li {
                padding:  0 0 0.5rem 0;
            }
        }
        
        .about-text-details-detail {
            margin: 0 0 8rem 0;
        }
    }
}
