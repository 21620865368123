@import '../../variables.scss';

.point-list-regular {
    list-style-type: disc;
    font-family: $verdana;
    font-size: 2rem;
}

.point-list {
    list-style-type: disc;
    font-family: $marker;
    font-size: 2rem;

    li {
        padding: 1rem 0;
    }
}