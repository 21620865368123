.case-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10rem;
    
    
    .case-header-image {
        margin: 2rem;
        max-width: 20rem;
     }
     
     .case-header-description {
        margin: 2rem
     }
}