@import '../../variables.scss';

@media only screen and (max-width: 900px) {
    .thumbnail  {
        margin: 4rem 0;
    }

    .rotated {
        padding: 2rem;
        border-top: 0.5rem solid rgb(244, 235, 230);;
        border-bottom: 0.5rem solid rgb(244, 235, 230);;
    }

    .thumbnail-text{
        padding: 2rem 2rem 0 2rem;
    }

    .thumbnail-image {
        max-width: 70%;
    }
}
  
@media only screen and (min-width: 900px) {
    .thumbnail {
        margin: 10rem 10rem 15rem 10rem;
    }

    .thumbnail-text{
        padding: 5rem 5rem 0 5rem;
    }
}

.thumbnail {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.thumbnail-image{
    max-height: 30rem;
    height: 100%;
    align-self: center;
}

.thumbnail-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.thumbnail-text-header {
    font-weight: $bold-font;
    margin-bottom: 3rem;
}

.thumbnail-text-description {
    margin-bottom: 1rem;
    max-width: 60rem;
}

.thumbnail-text-link {
    font-family: $georgia;
    font-weight: $bold-font;
    color: $gray;
    font-size: 2rem;

    &:hover {
        color: $dark-gray;
    }
}