/* autoprefixer grid: on */

@import './variables.scss';

@media only screen and (max-width: 900px) {
  .case-wrapper  {
      padding: 2rem;
  }
}

@media only screen and (min-width: 900px) {
  .case-wrapper {
      padding: 17rem;
  }
}

html {
  // font-size: 62.5%; /* 62.5% of 16px = 10px */
  font-size: 50%; /* 50% of 16px = 8px */
  user-select: none;
}

body {
  font-family: $georgia;
  font-size: 2.5rem;
  line-height: 3.75rem;
  margin: 0;
  width: 100%;
}

a {
  color: inherit; 
  text-decoration: inherit; 
}

ul {
  list-style-type: none;
  list-style-position: inside;
  padding-inline-start: 0;
 }

 p {
   margin-block-start: 0;
   margin-block-end: 0;
 }

 .email {
  user-select: all;
 }

 .bold {
  font-weight: $bold-font;
 }

 .verdana {
   font-family: $verdana;
 }

 .text {
  font-family: $verdana;
  font-size: 2rem;
  margin: 0 0 2rem 0;
}

.case-wrapper {
  display: flex;
  flex-direction: column;
}

.sub-header {
  font-family: $marker;
  margin: 5rem 0 0 0;
  font-size: 2.5rem;
}