.app {
    display: flex;
    flex-direction: row;
}

.app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
    max-width: 100%;
}