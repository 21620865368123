/* autoprefixer grid: on */

@import '../../variables.scss';

.travel-crew-context-comparison {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    font-size: 2rem;
    padding: 2rem 0 4rem 0;

    .travel-crew-context-comparison-pros-header,
    .travel-crew-context-comparison-cons-header {
        margin: 2.5rem 0;
        font-family: $marker;
        font-weight: $bold-font;
    }

    .travel-crew-context-comparison-pros-list,
    .travel-crew-context-comparison-cons-list {
        font-family: $verdana;

        div {
            margin: 2rem 0;
        }
    }

    .travel-crew-context-comparison-cons-list {
        color: #600808;
    }
}