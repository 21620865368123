.footer {
    margin: 10rem 1rem 1rem;
    padding: 4rem 6rem 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
        padding: 0 2rem;
    }
}