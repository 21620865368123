@import '../../variables.scss';

@media only screen and (max-width: 900px) {
    .my-work-header  {
        padding: 2rem 5rem;
    }
}
  
  @media only screen and (min-width: 900px) {
    .my-work-header {
        padding: 0rem 15rem 4rem 15rem
    }
}

.my-work-header {
    font-weight: $bold-font;
    font-size: 3rem;
}