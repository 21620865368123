@import '../../variables.scss';

.navigation {
    display: flex;
    justify-content: flex-end;
    padding: 4rem 12rem 4rem 8rem;
}

.navigation-links {
    display: flex;
    flex-direction: column;
    font-weight: $bold-font;
    align-items: flex-end;
}

.navigation-links-link {
    color: $light-gray;
    margin: 1rem 0;

    &:hover {
        color:$dark-gray;
    }
}