@media only screen and (max-width: 900px) {
    .prototypes-prototype  {
        padding: 2rem;
    }
  }
  
  @media only screen and (min-width: 900px) {
    .prototypes-prototype {
        padding: 6rem;
    }
  }

.prototypes {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}