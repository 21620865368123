@import '../../variables.scss';

.persona {
    margin: 4rem 0;

    .persona-name {
        font-family: $marker;
        font-size: 3.5rem;
    }

    .persona-description {
        font-family: $verdana;
        font-size: 2rem;
        margin: 2rem 0;
    }

    .persona-cases {
        font-family: $verdana;
        font-size: 2rem;
    }
}