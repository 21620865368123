.back-home {
    font-weight: 700;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    margin: 15rem 0 0rem 0;

    &:hover {
        cursor: pointer;
    }
}