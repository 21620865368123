@import '../../variables.scss';

.items-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 2.25rem;;

    li {
        list-style-type: none;
        padding: 1rem 10rem 0 0;
        text-transform: uppercase;
        font-family: $marker;
    }
}