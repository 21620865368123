@import '../../variables.scss';

@media only screen and (max-width: 900px) {
    .case-row {
        margin: 2rem 0;

        .case-row-content  {
            width: 100%;
        }
    
        .case-row-header {
            padding: 4rem 0;
        }
    }
}

@media only screen and (min-width: 900px) {

    .case-row {
        margin: 2rem 0;

        .case-row-content {
            width: 70%;
        }
    
        .case-row-header {
            padding: 0;
        }
    }
}

.case-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .case-row-header-image {
        margin: 5rem 0;
    }

    .case-row-header {
        font-weight: $bold-font;
        font-size: 3rem;
        letter-spacing: 0.5rem;
        width: 30%;
    }

    .case-row-subheader {
        font-weight: $bold-font;
        font-size: 2.5rem;
        letter-spacing: 0.5rem;
        width: 30%;
    }

    .case-row-content{

        .case-row-content-image-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 80%;
            
            .case-row-content-image{
                object-fit: cover;
                max-width: 100%;
                margin: 2rem 0;
            }

            .case-row-image-description {
                font-size: 1.25rem;
                font-family: $verdana;
                line-height: 1.5rem;
                color: $light-gray;
            }
        }

    }
}