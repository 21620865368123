@import '../../variables.scss';

.travel-crew-user-needs {
    display: flex;
    flex-direction: column;

    .travel-crew-user-needs-row {
        display: flex;
        flex-direction: row;
        padding: 2rem 0;

        .travel-crew-user-needs-row-box {
            width: 30%;
            padding: 0 2rem;
            
            .travel-crew-user-needs-row-header {
                font-family: $marker;
                font-size: 2rem;
            }
        }
    }
}