/* autoprefixer grid: on */

.case-description {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 8rem 0;


    .case-description-header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        font-size: 2.25rem;


        div {
            padding: 2rem 0;
        }
    }
    
    .case-description-content {
        display: flex;
        justify-content: center;
        width: 60%;

        .case-description-content-image {
            max-width: 20rem;
        }
    }
}